import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/annual-report/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Whilst Strategic Plan Goals target the future growth of the school, Leading School Benchmarks (LSBs) are being designed to ensure that we never lose sight of our mission.  These benchmarks are internal and external measures that focus on the standards we want to hold ourselves accountable to for the foreseeable future.  By measuring and reporting annually on all critical aspects that the PSI community believes characterise a leading school, the school can better focus on the aspirational Strategic Plan Goals and the school’s growth.`}</p>
    <p>{`The Board of Governors began the process of developing these LSBs in the spring of 2019 during a retreat with its coach, Dr. Andy Page-Smith and the PSI Leadership Team.  During that meeting, the group decided that key areas of focus would include Community, Diversity & Inclusion; Faculty; Financial and Resource Sustainability; Student Success; and Wellbeing.  Then, during another retreat in the fall of 2019, the Board of Governors created five working groups to make recommendations for these benchmarks, their objectives, and respective measures.`}</p>
    <p>{`In the beginning of 2020, the Board of Governors created the Strategic Oversight Committee (SOC), a sub-committee formed to support the work of the Governance and Strategy Committee, oversee the implementation of the Strategic Plan and the Leading School Benchmarks (LSBs). The Strategic Oversight Committee is composed of volunteer representatives from faculty, the Leadership Team, the parent community and the Board of Governors.`}</p>
    <p>{`This group has since been meeting regularly to determine the appropriate measures for our Leading School Benchmarks.  This process has been comprehensive in reviewing what is important to the community and is expected to be completed with recommendations to the Board of Governors of a list of measures for the LSBs in the fall of 2020.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/688447a3ac23daaaddee7599caacbe87/a01dc/schema.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAACsklEQVQ4y3VTS2gTURQtirixuBGXooKCC10piuBaKaKCC0ELfqAWtOBKpPjZuBEL1YUf8ANduKmr+qmi1tpWWmtb0JiapG2aJk3TppmZzEwm83n3zeTIvEmmDcULlzvv3jtnzj3vToPruqg55xzECEQETgRiTDzX6sR50EMERkGf5dh1PQ1YZS7nKCTnIKUyKMymRSTbCetLRQmTczOYXZxHsuqSroJxCnsaKpUKhAMimqoGQ1ZQkmSUJAWe64m8b0VDRywzi3xRgs0clG1TsAoxKpWAYe0FVCqwjTIsvQTuMJHyvBVAf0zucpiODUXXBKDv3HVRwwkZ1hK+dr75DBODI+CMBHufqQ/+fnQAv5JxwcyybbirPhgC1rGs2suL13BzzxF8fvgszHUPfMS+yydxrL0Fy5oS5lcmRP3IRAzfv/Yiv5DF8+aruLHrEPo6n+B3IotIJIPuwQ/Y23ISTbdakUjPYag/CrVo1IEKhl5Vg6cdd3Bw20a0NTchl5hBrPcLfoxEsWP/FWzZfR5vP43h3Vg/xqejuH29C43rj+PSuY4QcI2G99rbcHjnJjQfPQCHBesyMj6Fxu1nsW7raXT3DIdjtl7oxOYNJ3Dm1N21GhJzxEEu5NH1+D5ikQnRYJm2iK/fDOPFqz4w4rCqe5mdL+DRgx7MTC0EG+AQPK+6NovZNJKJSaSTU5ALS8jn5sEYYXRiGsM/45iMZ5DK5BGNZTCTWoTreoj9zSARzyIaSWHo2x8kp3Mre2iaZUiFPDS1CE1VYFumKEqyjvyyiqVlFYpqwHEInAd6a2oZuQUZsqRDkXUYhhVcihjNsiArCsqmCdM0xb/pA2q6CUnRoRQN4apWhmkFI9s2g6aVYZQsmKYDIh4wJCL8z4hcwYoxDoeRkMGPvvlM/byoOSRk8Bn+AyfdAStG7/2fAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "schema",
            "title": "schema",
            "src": "/static/688447a3ac23daaaddee7599caacbe87/c1b63/schema.png",
            "srcSet": ["/static/688447a3ac23daaaddee7599caacbe87/5a46d/schema.png 300w", "/static/688447a3ac23daaaddee7599caacbe87/0a47e/schema.png 600w", "/static/688447a3ac23daaaddee7599caacbe87/c1b63/schema.png 1200w", "/static/688447a3ac23daaaddee7599caacbe87/d61c2/schema.png 1800w", "/static/688447a3ac23daaaddee7599caacbe87/a01dc/schema.png 2006w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      